import * as React from 'react'
import { Link } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import Helmet from 'react-helmet'

const PageTwo = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>


  </IndexLayout>
)

export default PageTwo
